
@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-steps-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 70px;
}

.questionnaire {
  display: flex;
}

.questionnaire-step {
  width: 17%;
}

.questionnaire-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65%;
  justify-content: center;
}

.questionnaire-economique-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65%;
  justify-content: center;
}

.questionnaire-content button {
  width: 100px;
  border: 1px solid #1b317e !important;
  line-height: 25px;
  margin: 0 2px;
  color: rgba(0,0,0,0.65) !important;
  cursor: pointer;
}

.questionnaire-economique-content button {
  width: auto;
  border: 1px solid #f4f4f4 !important;
  line-height: 25px;
  margin: 0 2px;
  cursor: pointer;
  background: transparent !important;
  color: rgba(0,0,0,0.65) !important;
}

.questionnaire-content button:hover {
  background: #1b317e !important;
  color: white !important;
}

.questionnaire-economique-content button:hover {
  background: #1b317e !important;
  color: white !important;
}

.color-red {
  color: red !important;
  text-align: center;
  font-size: 15px !important;
  margin-top: 50px !important;
  padding-bottom: 0px !important;
}

.countryofbirth {
  display: flex;
}

.ant-space.contryofbirth {
  float: left;
  right: auto;
  top: auto;
  flex-direction: row;
  width: 33.33%;
}

.ant-space.contryofbirth .ant-space-item:last-child {
  width: calc(100% - 195px) !important;
}

.ant-space.contryofbirth h3 {
  padding: 6px 30px 6px 40px;
  width: 195px;
}

.contryofbirth .ant-select-selector{
  margin: 0 !important;
}

.steps-content .contryofbirth .ant-select {
  width: 100% !important;
}

.contryofbirth .ant-select-arrow {
  top: 20% !important;;
}

.etat-civil {
  margin-top: -93px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  padding: 0 10px !important;
  text-align: left;
}

.middle_content .form_content form .ant-form-item {
  width: 33.33% !important;
  float: left;
  display: block !important;
}

@media (max-width: 864px){
  .questionnaire {
    flex-direction: column-reverse;
  }
  .questionnaire-step {
    width: 100%;
  }

  .questionnaire-step .ant-steps-vertical {
    margin-top: 20px;
  }

  .questionnaire-content, .questionnaire-economique-content {
    width: 100%;
  }

  .ant-space.contryofbirth {
    flex-direction: column;
    width: 100%;
  }
  .etat-civil {
      margin-top: 10px;
  }

  .dateofbirth {
    flex-direction: column !important;
    width: 100%;
    margin: 0;
  }

  .ant-space.contryofbirth h3, .dateofbirth h3 {
    padding: 6px 30px 6px 0px;
    width: 100%;
  }

  .steps-content .contryofbirth .ant-select {
    width: 100% !important;
  }

  .remarque-inuputField {
    padding-left: 0px;
  }

  .remarque-inuputField .remarque-form textarea {
    width: 100%;
  }

  .middle_content .form_content .allInfo-form .allInfoFields .allInfo-couple-label-input-next .spanName {
    padding-bottom: 15px;
  }

  .dateofbirth .ant-space-item:last-child {
    width: 100%;
  }

  .ant-space.contryofbirth .ant-space-item:last-child {
    width: 100% !important;
  }

  .middle_content .form_content form .ant-form-item {
    width: 100% !important;
  }
  
}

.normal-form .ant-form-item-label > label {
  width: 107px !important;
}

.normal-form .ant-input {
  height: 50px;
}

.steps-content .normal-form .ant-select .ant-select-selector {
  margin-top: 0px;
}

.questionnaire-economique-content .normal-form button {
  border: 1px solid #1b327e !important;
  background: #1b327e !important;
  color: rgb(255 255 255) !important;
}

.normal-form .ant-form-item-label > label {
  height: 50px;
}

.normal-form .ant-select-arrow {
  top: 20%;
}

.questionnaire-economique-content button.active {
  border: 1px solid #1b327e !important;
  background: #1b327e !important;
  color: #FFF !important;
}

.identification {
  display: flex;
  width: 80%;
  margin: 0 auto;
  max-width: 850px;
  gap: 20px;
}

.identification > div{
  padding: 25px;
  width: 50%;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid #1b327e;
}

.identification > div p{
  padding-top: 20px;
  color: #1b317e;
}

.questionnaire-economique-content .normal-form button:disabled {
  border: 1px solid #1b327e21 !important;
  background: #1b327e47 !important;
  color: rgb(255 255 255) !important;
}

@media (max-width: 560px){
  .identification {
    flex-direction: column;
    width: 100%;
  }

  .identification > div{
    width: 100%;
  }
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 20%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  padding-bottom: 40px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  margin-top: -12px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

h1.red-line.color-red a {
  margin: 0;
  padding: 0;
  font-size: 15px;
  width: auto !important;
  height: auto !important;
  background: transparent !important;
  opacity: 1 !important;
  text-decoration: underline !important;
  color: red !important;
  line-height: 28px !important;
}

.ant-switch-checked {
  background-color: #1b327e;
}

.track-container {
  border: 2px solid rgba(0,0,0,0.04);;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.track-item {
  margin-bottom: 20px;
}

.track-img {
  width: 20px;
}

.track-label {
  margin-left: 10px;
}

.track-confirmer {
  color: #1b327e !important
}

button#axeptio_btn_acceptAll\ track-confirmer {
  color: #1b327e !important;
}