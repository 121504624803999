.form {
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
    padding: 84px 110px;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.05);
    z-index: 2;
  }

  .inputGroup {
    display: flex;
    width: 100%;
    max-width: 100%;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: Poppins, sans-serif;
    color: #000;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .label {
    font-size: 14px;
    font-weight: 400;
    padding-top: 12px;
    display: flex;
    gap: 5px;
  }
  
  .value {
    color: #132C70;
    text-align: right;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .redAccent {
    color: #1b327e;
  }

  .rangeContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), #edf1f4);
    border-radius: 40px;
  }

  .range {
    width: 100%;
    height: 22px;
    appearance: none;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
  }

  .range::-webkit-slider-thumb {
    appearance: none;
    width: 22px;
    height: 22px;
    background: #cac70d;
    border-radius: 50%;
    border: 6px solid #132C70;
    box-shadow: -407px 0 0 400px #9193A9;
    cursor: pointer;
 } 

  .card {
    background-color: #1b327e;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.05);
    z-index: 5;
    justify-self: end;
    font-family: Poppins, sans-serif;
    margin: -30px -57px auto 0;
    padding: 38px 48px 14px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .heading {
    color: #fff !important;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .button {
    margin: -50px 340px 10px 0px;
    font-size: 12px;
    font-weight: 600;
    color: #1b327e;
    text-align: center;
    padding: 10px 32px;
    background-color: #FFD931;
    border: none;
    justify-self: end;
    z-index: 6;
  }

  .result {
    display: flex;
    flex-direction: row;
    gap: 9rem;
    font-family: Poppins, sans-serif;
    justify-content: space-between;
  }
  
  @media (max-width: 991px) {
    .form {
      max-width: 100%;
      padding: 20px;
    }

    .result {
        display: flex;
        flex-direction: row;
        gap: 4rem;
        font-family: Poppins, sans-serif;
        justify-content: space-between;
    }

    .card {
        margin: 0;
        padding: 20px 10px;
        width: max-content;
    }

    .button {
      margin: 0;
    }
  }