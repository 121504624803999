.container {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
    padding: 50px 80px;
  }
  
  .wrapper {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
  
  .title {
    color: #000;
    text-align: center;
    align-self: center;
    font: 600 40px Poppins, sans-serif;
  }
  
  .content {
    margin-top: 70px;
  }
  
  .columns {
    gap: 20px;
    display: flex;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 24%;
    margin-left: 0;
  }
  
  .sidePanel {
    width: 20%; 
    height: 230px;
    background-color: #9193A9;
  }

  .rightColumn {
    display: grid;
    line-height: normal;
    width: 76%;
    margin-left: -15px;
  }
  
  @media (max-width: 991px) {
    .container {
      padding: 50px 20px;
    }
    
    .wrapper {
      max-width: 100%;
    }
    
    .title {
      max-width: 100%;
    }
    
    .content {
      max-width: 100%;
      margin-top: 0px;
    }
    
    .columns {
      flex-direction: column;
      align-items: stretch;
      gap: 20px;
    }
    
    .leftColumn {
        width: 86%;
        margin-left: 0;
    }
    
    .rightColumn {
      width: 100%;
      margin-left: 0;
    }
  }