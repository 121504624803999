.card {
  background-color: #1b327e;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.05);
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-family: Poppins, sans-serif;
  margin: 120px -57px auto 0;
  padding: 25px 48px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
  
.heading {
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 34px;
}

.description {
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-bottom: 33px;
}

.button {
  margin-top: 32px;
  font-size: 12px;
  font-weight: 600;
  color: #1b327e;
  text-align: center;
  padding: 17px 32px;
  background-color: #FFD931;
  border: none;
  cursor: pointer;
}

@media (max-width: 991px) {
  .card {
    margin-top: 40px;
    padding: 20px;
  }
  
  .heading {
    margin: 0 9px;
  }
  
  .button {
    margin: 0 2px;
    padding: 17px 20px;
  }

  .description {
    margin-bottom: 33px !important;
  }
}